import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as MuiLink } from '@material-ui/core';

function Link(props) {
  const componentProps = {};

  switch (props.variant) {
    default: {
      componentProps.component = RouterLink;
      componentProps.to = props.to;
      break;
    }

    case 'email': {
      componentProps.href = `mailto: ${props.to}`;
      break;
    }

    case 'external': {
      componentProps.href = `//${props.to}`;
      componentProps.target = '_blank';
      break;
    }

    case 'function': {
      componentProps.onClick = props.onClick;
      break;
    }

    case 'telephone': {
      componentProps.href = `tel: ${props.to}`;
      break;
    }
  }

  return (<MuiLink
    children={props.children}
    underline="none"
    style={{
      cursor: 'pointer', 
    }}
    {...componentProps}
  />);
}

Link.defaultProps = {
  onClick: () => { },
  variant: 'router',
};

Link.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  variant: PropTypes.oneOf([
    'email',
    'external',
    'function',
    'router',
    'telephone',
  ]),
};

export default Link;
