/**
 * Pupulate string with values in object
 *
 * @param {string} str String to populate
 * @param {object} values Data to populate string
 * @param {string} prefix Prefix for root values
 * @returns {string} Populate string
 */
function populate(str, values = {}, prefix = ':') {
  let output = str;

  for (const [k, v] of Object.entries(values)) {
    output = v !== null && typeof v === 'object'
      ? populate(output, v, k)
      : output.replace(`${prefix}${k}`, v || '');
  }

  return output;
}

/**
 * Slugifies a string
 *
 * @param {string} str String to slugify
 * @param {string} replacement Replace characters with this
 * @returns {string}
 */
function slugify(str, replacement = '_') {
  let output = str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase();

  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    output = output.replace(
      new RegExp(from.charAt(i), 'g'),
      to.charAt(i),
    );
  }

  return output.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, replacement)
    .replace(/-+/g, replacement);
}

export {
  populate,
  slugify,
};
