import { Box, Typography } from '@material-ui/core';
import JSONInput from 'react-json-editor-ajrm';
import localeEs from 'react-json-editor-ajrm/locale/es';
import PropTypes from 'prop-types';

function JsonControl(props) {
  const {
    events,
    label,
    name,
    value: placeholder = {},
    onBlur,
    onChange,
  } = props;

  const handleBlur = ({ jsObject: value = null }) => {
    const target = {
      name,
      value,
    };

    onBlur({
      target,
    });
    onChange({
      target,
    });
  };

  const handleChange = ({ jsObject: value = null }) => {
    const target = {
      name,
      value,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  return (<fieldset style={{
    borderRadius: 5,
  }}>
    {label && <legend style={{
      marginLeft: 8,
    }}>
      <Typography color="textSecondary" variant="caption">
        <Box style={{
          marginLeft: 5,
          marginRight: 5,
        }}>
          {label}
        </Box>
      </Typography>
    </legend>}
    <Box m={1}>
      <JSONInput
        height='250px'
        locale={localeEs}
        placeholder={placeholder}
        waitAfterKeyPress={1500}
        width={'100%'}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Box>
  </fieldset>);
}

JsonControl.defaultProps = {
  events: {},
  value: {},
  onChange: () => { },
  onBlur: () => { },
};

JsonControl.propTypes = {
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default JsonControl;
