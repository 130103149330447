function getCircularReplacer() {
  const seen = new WeakSet();

  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

function stringifyDecycle(obj, space) {
  return JSON
    .stringify(obj, getCircularReplacer(), space);
}

function parseIfValid(str, returnOriginal = true) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return returnOriginal ? str : null;
  }
}

export {
  parseIfValid,
  getCircularReplacer,
  stringifyDecycle,
};
