import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function InputPercentage(props) {
  const { name, inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ value }) => {
        onChange({
          target: {
            name,
            value, 
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix=" %"
    />
  );
}

InputPercentage.propTypes = {
  name: PropTypes.string,
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputPercentage;
