import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_REGION,
} from '../';

export const AWS_USER_POOLS_ID = 'us-west-2_je1UqTzqY';
export const AWS_USER_POOLS_WEB_CLIENT_ID = '6f870ne6o7hnutod2shbooiit6';

export const AWS_AMPLIFY = {
  aws_project_region: AWS_REGION,
  aws_cognito_identity_pool_id: AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};
