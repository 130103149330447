export const SERVICES = {
  ACCOUNTS: '/private/accounts',
  ACCOUNTS_DESTINATION: '/private/accounts/destination',
  ACCOUNTS_MASSIVE_MOVEMENTS: '/private/accounts/massivemovements',
  ACCOUNTS_MASSIVE_PRODUCTS: '/private/accounts/massive/products',
  ACCOUNTS_MOVEMENTS: '/private/accounts/movements',
  ACCOUNTS_OPERATIONS: '/private/accounts/operations',
  ACCOUNTS_PROCESSOR: '/private/accounts/processor',
  ACCOUNTS_TRANSACTIONS: '/accounts/:account/transactions',

  CATALOGS_FIELDS: '/private/catalog/templates/fields',
  CATALOGS_TEMPLATES: '/private/catalog/public_template/id',

  DASHBOARD_CHARTS: '/private/dashboard',
  DASHBOARD_CHARTS_DATA: '/private/dashboard/data',
  DASHBOARD_CHARTS_TYPE: '/private/dashboard/charts',

  REPORT_KPI: '/private/reports/kpi',

  SIMULATOR: '/private/demo/table',

  AMORTIZATION_INIT: '/private/accounts/ideal_table',
  AMORTIZATION_UPDATE: '/private/accounts/table',

  CAMPAIGNS: '/private/campaign',
  CAMPAIGNS_COMPARATOR: '/private/campaign/comparators',
  CAMPAIGNS_CONDITIONALS: '/private/campaign/conditionals',
  CAMPAIGNS_CONDITIONALS_FIELDS: '/private/campaign/conditionals/fields',
  CAMPAIGNS_HISTORICAL: '/private/campaign/historical',
  CAMPAIGNS_LINKS: '/private/campaign/links',
  CAMPAIGNS_RESULTS: '/private/campaign/result',
  CAMPAIGNS_TYPES: '/private/campaign/types',

  CC: '/private/cc',

  COMPANY: '/private/company',
  COMPANY_FIELDS: '/private/company/fields',

  CRONTAB: '/private/crontab',

  CONNECTOR_CORE: '/internal/connector/:company/core',
  CONNECTOR_SET_CLABE: '/internal/connector/:company/setclabe',
  CONNECTOR_FLOW: '/internal/connector/:company/flows/:flow',

  FINANCIAL_IRR: '/internal/financial/irr',

  FLOWS: '/private/diagrams/flows',
  FLOWS_CLASS: '/private/diagrams/class',
  FLOWS_NODES: '/private/diagrams/nodes',
  FLOWS_TYPES: '/private/diagrams/flows_types',

  NOTIFICATIONS_ALERTS: '/private/notifications/alerts',
  NOTIFICATIONS_FIELDS: '/private/notifications/get_fields',
  NOTIFICATIONS_TEMPLATES: '/private/notifications/templates',

  PEOPLE: '/private/clients',
  PEOPLE_TEMPLATES: '/private/clients/template',
  PEOPLE_TYPES: '/private/clients/types',
  PEOPLE_TYPES_IFRAME: '/private/clients/types/create_iframe',

  PRODUCTS: '/private/products',
  PRODUCTS_OPERATIONS: '/private/products/operations',
  PRODUCTS_OPERATIONS_CATALOG: '/private/products/operations/catalog',
  PRODUCTS_TYPES: '/private/products/types',

  REPORTS: 'private/reports/config',
  REPORTS_FIELDS: '/private/reports/loan_fields',
  REPORTS_DATA: '/private/reports/loan_tape',

  SAT: '/private/sat/data',
  SAT_SYNC: '/internal/sat/sat_cfdi',
  SAT_KEY: '/internal/sat/sat_key',
  SAT_GET_KEY: '/private/sat/sat_key',
  SAT_STATUS: '/private/sat/query_status',
  SAT_CFDI: '/internal/load_cfdi',
  SAT_BALANCE: '/private/sat/monthly_amounts',
  SAT_BALANCE_CLIENTS: 'private/sat/partners_percent',

  USERS: '/private/users',
  USERS_ADD: '/users/add',
  USERS_CLIENTS: '/users/clients',
  USERS_CREATE: '/users/create',
  USERS_GET_INFO: '/private/users/get_info',
  USERS_OWNER: '/users/owner',
  USERS_ROLES: '/private/users/roles',
  USERS_ROLES_TEMPLATE: '/private/users/rolestemplate',
  USERS_WEBCLIENTS: '/users/webclients',

  WS_PUBLISH: '/internal/wspublish',
};

export const CONF = {
  [SERVICES.PEOPLE_TEMPLATES]: {
    getById: false,
    sort: 'score',
  },
  [SERVICES.DASHBOARD_CHARTS_DATA]: {
    getById: false,
  },
};
