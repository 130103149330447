import {
  useEffect, useRef, useState, 
} from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ExitToApp as ExitToAppIcon,
  VpnKey as VpnKeyIcon,
} from '@material-ui/icons';

import UpdatePasswordModal from './UpdatePasswordModal';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  avatarArrow: {
    paddingLeft: '0.15em',
  },
  popover: {
    width: 250,
  },
}));

function AccountMenuControl() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [modal, setModal] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async function () {
    try {
      await Auth.signOut({
        global: true, 
      });

      localStorage.clear();

      history.push('/auth/login');
    } catch (error) {
      enqueueSnackbar('Error al salir', {
        variant: 'error',
      });
    }
  };

  const menuOptions = [
    {
      icon: <VpnKeyIcon fontSize="small" />,
      label: 'Cambiar contraseña',
      fn: () => setModal({
        events: {
          onClose: () => setModal(),
        },
        open: true,
      }),
    },
    {
      icon: <ExitToAppIcon fontSize="small" />,
      label: 'Cerrar sesión',
      fn: handleLogout,
    },
  ];

  useEffect(() => {
    Auth
      .currentUserInfo()
      .then((data) => {
        const {
          attributes: { name = '', family_name = '' } = {},
          username,
        } = data;

        data.label = `${name} ${family_name}`.trim();
        if (!data.label) {
          data.label = username;
        }

        setCurrentUser(data);
      });
  }, []);

  return (
    <>
      <UpdatePasswordModal {...modal} />

      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src="" />
        {currentUser && <Hidden smDown>
          <Typography
            children={currentUser?.label}
            color="inherit"
            variant="h6"
          />
          {isOpen
            ? <ArrowDropUpIcon className={classes.avatarArrow} />
            : <ArrowDropDownIcon className={classes.avatarArrow} />
          }
        </Hidden>}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{
          className: classes.popover, 
        }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {menuOptions.map((item, index) => {

          if (item.type === 'divider') {
            return (<Divider />);
          }

          return (<MenuItem
            children={<>
              <ListItemIcon
                children={item.icon}
              /> {item.label}
            </>}
            key={index}
            onClick={() => {
              handleClose();

              if (typeof item.fn === 'function') {
                item.fn();
              }
            }}
          />);
        })}
      </Menu>
    </>
  );
}

export default AccountMenuControl;
