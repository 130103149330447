import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'layouts/DashboardLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const dashboardRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  path: '/',
  routes: [
    {
      component: lazy(() => import('views/Dashboard/List')),
      exact: true,
      path: '/dashboard',
    },
    {
      component: lazy(() => import('views/Dashboard/Details')),
      exact: true,
      path: '/dashboard/:id',
    },
    {
      component: lazy(() => import('views/Payments')),
      exact: true,
      path: '/payments',
    },
    {
      component: lazy(() => import('views/Profile')),
      exact: true,
      path: '/profile',
    },
    {
      component: lazy(() => import('views/History')),
      exact: true,
      path: '/history',
    },
    error404,
  ],
};

export default dashboardRoutes;
