import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { FolderOpen as FolderOpenIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  label: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

function FileControl(props) {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [currentError, setCurrentError] = useState(false);
  const {
    disabled,
    error,
    label,
    name,
    size,
    settings,
    onChange,
  } = props;
  const {
    accept = [],
    base64 = false,
    multiple = false,
  } = settings;
  const controlId = `control_${name}_${Math.floor(Math.random() * 100000)}`;
  let inputFile;

  const handleSelectFile = function () {
    inputFile.click();
  };

  const handleFileChange = async function ({ target: { files } }) {
    setCurrentError(false);
    const names = [];
    let filesOutput = [];
    const validExt = accept.map(_ => _.toLowerCase());

    if (!base64) {
      filesOutput = files;
    }

    for await (const file of files) {
      const [, ext] = file
        .name
        .split('.')
        .map(_ => _.toLowerCase());

      names.push(file.name);

      if (base64) {
        filesOutput.push(await toBase64(file));
      }

      if (!currentError && validExt.length) {
        setCurrentError(validExt.includes(ext) ? '' : 'Invalid file type');
      }
    }

    setText(names.join(', '));
    const target = {
      name,
      value: filesOutput,
    };
    onChange({
      target,
    });
  };

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  return (
    <FormControl
      disabled={disabled}
      error={currentError}
      fullWidth
      size={size}
      variant="outlined"
    >
      <input
        accept={accept.map(_ => `.${_}`).join()}
        hidden
        multiple={multiple}
        onChange={handleFileChange}
        ref={input => inputFile = input}
        type="file"
      />
      <InputLabel
        className={classes.label}
        children={label}
        htmlFor={controlId}
        shrink={!!text}
      />
      <OutlinedInput
        readOnly
        id={controlId}
        type="text"
        value={text}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              children={<FolderOpenIcon />}
              disabled={disabled}
              onClick={handleSelectFile}
              edge="end"
            />
          </InputAdornment>
        }
      />
      {currentError && (<FormHelperText children={currentError} />)}
    </FormControl>);
}

FileControl.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
};

FileControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  settings: PropTypes.shape({
    accept: PropTypes.array,
    base64: PropTypes.bool,
    multiple: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

export default FileControl;
