import * as Yup from 'yup';

import {
  EMAIL_REGEXP,
  PASSWORD_REGEX,
  PHONE_REGEXP,
} from 'constants/index';
import MESSAGES from 'validations/constants/messages';

const passwordField = Yup
  .string()
  .required(MESSAGES.REQUIRED)
  .matches(PASSWORD_REGEX, MESSAGES.PASSWORD);
const usernameField = Yup
  .string()
  .trim()
  .lowercase()
  .required(MESSAGES.REQUIRED)
  .test('validateUsername', MESSAGES.USERNAME, function (value) {
    if (EMAIL_REGEXP.test(value)) {
      return true;
    } else if (PHONE_REGEXP.test(value)) {
      return true;
    } else {
      return false;
    }
  });

export const changePasswordSchema = Yup
  .object()
  .shape({
    oldPassword: passwordField,
    newPassword: passwordField,
  });

export const signInSchema = Yup
  .object()
  .shape({
    username: usernameField,
    password: passwordField,
  });

export const signUpSchema = Yup
  .object()
  .shape({
    username: usernameField,
    password: passwordField,
  });
