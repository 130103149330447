import React from 'react';
import PropTypes from 'prop-types';
import {
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function LinearStepper({ activeStep, steps }) {
  const classes = useStyles();

  return (<Stepper activeStep={activeStep} className={classes.root}>
    {steps.map((step, index) => {
      const stepProps = {};
      const labelProps = {
        children: step.label,
      };

      if (step.optional) {
        labelProps.optional = <Typography children="Opcional" variant="caption" />;
      }

      return (
        <Step key={index} {...stepProps}>
          <StepLabel {...labelProps} />
        </Step>
      );
    })}
  </Stepper>);
}

LinearStepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

LinearStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    optional: PropTypes.bool,
  })),
};

export default LinearStepper;
