import AwsS3 from 'aws-sdk/clients/s3';

import {
  AWS_BUCKET as BUCKET,
  AWS_CREDENTIALS as CREDENTIALS,
  AWS_REGION as REGION,
} from 'settings/aws';

const client = new AwsS3({
  apiVersion: '2006-03-01',
  credentials: CREDENTIALS,
  region: REGION,
});

/**
 * Get signed URL
 *
 * @param {string} path
 */
export const getSignedUrl = async (path, Expires = 10, repository = BUCKET) => {
  path = path.replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');

  const params = {
    Bucket: repository,
    Expires,
    Key: path,
  };

  return client.getSignedUrl('getObject', params);
};

/**
* Upload file to AWS S3
*
* @param {string} path
* @param {(Buffer|Uint8Array|Blob|string|Readable)} file
*/
export const upload = async (path, file) => {
  const params = {
    Body: file,
    Bucket: BUCKET,
    ContentType: file.type,
    Key: path,
  };
  const options = {
    partSize: 50 * 1024 * 1024,
    queueSize: 1,
  };

  return client.upload(params, options).promise();
};

/**
* Download file from AWS S3
*
* @param {string} path
*/
export const download = async (path) => {
  try {
    const url = await getSignedUrl(path);
    window.open(url);
  } catch (error) {
    return false;
  }

  return true;
};
