import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import CodeControl from 'components/DynamicForm/controls/CodeControl';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    verticalAlign: 'top',
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function KeyValueList({ items }) {
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        {items && items.map((item, index) => {
          let { label = '', value = '' } = item;

          if (Array.isArray(item)) {
            [label, value] = item;
          }

          return (
            <TableRow key={index}>
              {(label !== false) && <TableCell
                children={label}
                className={classes.fontWeightMedium}
              />}
              <TableCell>
                <Typography
                  children={typeof value === 'object' && !value?.props
                    ? <CodeControl
                      disabled
                      settings={{
                        lang: 'json',
                        lineNumbers: false,
                      }}
                      value={JSON.stringify(value, null, '\t')}
                    />
                    : value}
                  color="textSecondary"
                  variant="body2"
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

KeyValueList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
};

export default KeyValueList;
