import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
} from '@material-ui/core';
import dot from 'dot-object';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

function TextControl(props) {
  const [revealPassword, setRevealPassword] = useState(false);
  const {
    disabled,
    required,
    display,
    error,
    events,
    settings,
    size,
    onBlur,
    onChange,
    useSetFieldValues,
  } = props;
  let { type } = props;

  if (type === 'password' && settings?.canReveal) {
    display.endIcon = revealPassword
      ? <VisibilityOffIcon />
      : <VisibilityIcon />;
    display.endIconAction = () => setRevealPassword(!revealPassword);
    display.endIconTitle = revealPassword
      ? 'Ocultar'
      : 'Visualizar';
    type = revealPassword ? 'text' : type;
  }

  const Title = display.endIconTitle ? Tooltip : Fragment;

  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth={true}
      helperText={props.helperText}
      InputProps={{
        endAdornment: display.endIcon
          ? (<InputAdornment position="end">
            {display.endIconAction
              ? (<Title {...display.endIconTitle
                ? {
                  title: display.endIconTitle, 
                }
                : {}}
              >
                <IconButton
                  children={display.endIcon}
                  onClick={display.endIconAction}
                  size="small"
                />
              </Title>)
              : (<SvgIcon
                children={display.endIcon}
                color="action"
                fontSize="small"
              />)}
          </InputAdornment>)
          : null,
        readOnly: settings?.readOnly,
        startAdornment: display.startIcon
          ? (<InputAdornment position="start">
            <SvgIcon
              children={display.startIcon}
              color="action"
              fontSize="small"
            />
          </InputAdornment>)
          : null,
      }}
      label={props.label}
      InputLabelProps={{
        shrink: !!props.value,
      }}
      multiline={settings?.multiline}
      name={props.name}
      placeholder={props.placeholder}
      rows={settings?.rows}
      rowsMax={settings?.rowsMax}
      size={size}
      type={type}
      variant="outlined"
      value={props.value}
      required={required}
      onKeyPress={(e) => {
        if (events.onKeyPress) {
          events.onKeyPress(e);
        }
      }}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          const [setFieldValue, values] = useSetFieldValues;
          dot.str(e?.target?.name, e?.target?.value, values);
          events.onChange(
            e?.target,
            setFieldValue,
            values,
          );
        }
      }}
    />
  );
}

TextControl.defaultProps = {
  disabled: false,
  required: false,
  display: {},
  error: false,
  events: {},
  type: 'text',
  onBlur: () => { },
  onChange: () => { },
  useSetFieldValues: [() => { }, {}],
};

TextControl.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  display: PropTypes.shape({
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    endIconAction: PropTypes.func,
    endIconTitle: PropTypes.string,
  }),
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox',
    'email',
    'hidden',
    'password',
    'radio',
    'text',
  ]),
  value: PropTypes.any,
  settings: {
    multiline: PropTypes.bool,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
  },
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  useSetFieldValues: PropTypes.array,
};

export default TextControl;
