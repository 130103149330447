import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    border: '1px solid transparent',
    color: '#212529',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c3d6fe',
      border: '1px solid transparent',
      borderColor: '#8CB0FD !important',
      borderRadius: '0.25rem',
      transition: [
        'color .15s ease-in-out',
        'background-color .15s ease-in-out',
        'border-color .15s ease-in-out',
        'box-shadow .15s ease-in-out',
      ].join(','),
    },
    '&:active': {
      backgroundColor: '#8CB0FD',
      borderColor: '#8cb0fd',
    },
  },
  cardSelected: {
    backgroundColor: '#8CB0FD',
    border: '1px solid transparent',
    borderRadius: '0.25rem',
    color: '#212529',
    cursor: 'pointer',
    transition: [
      'color .15s ease-in-out',
      'background-color .15s ease-in-out',
      'border-color .15s ease-in-out',
      'box-shadow .15s ease-in-out',
    ].join(','),
  },
}));

function CardSelector(props) {
  const classes = useStyles();
  const {
    error,
    events,
    name,
    onChange,
    settings = {},
    value,
  } = props;

  const { optionsPerRow = 2 } = settings;

  const options = typeof settings.options === 'function'
    ? settings.options()
    : settings.options || [];

  const handleChange = (value) => {
    const target = {
      name,
      value,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  return (<>

    {error && (<Alert
      children={error}
      severity="error"
    />)}

    <Grid container spacing={2}>
      {options.map((option, index) => (
        <Grid key={index} item md={12 / optionsPerRow} xs={12 / optionsPerRow}>
          <Card
            onClick={() => handleChange(options[index].id)}
            className={clsx(value === option.id ? classes.cardSelected : classes.card)}
          >
            <CardContent align='center'>
              <Typography
                children={option.description || <br />}
                sx={{
                  fontSize: 14,
                }}
              />
              <Typography
                children={option.name}
                variant="h5"
              />
              <Typography
                children={option.subtitle || <br />}
                variant="h5"
                color={'#2464F0'}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </>);
}

CardSelector.propTypes = {
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    optionsPerRow: PropTypes.number,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
  value: PropTypes.string,
};

export default CardSelector;
