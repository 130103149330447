import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Delete as DeleteIcon,
  PostAdd as PostAddIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import { Modal } from 'components';
import { MIME_TYPES } from 'constants/files';
import { getSignedUrl, upload } from 'utils/aws/s3';

const EXT_IMAGES = MIME_TYPES
  .filter(_ => _.type === 'image')
  .map(_ => _.name) || [];

const useStyles = makeStyles((theme) => ({
  button: {
    border: '#00000014',
    borderRadius: '5%',
    borderStyle: 'dashed',
    padding: '2px',
  },
  imageList: {
    flexWrap: 'nowrap',
    margin: '10px !important',
    minWidth: theme.spacing(12),
  },
  rootImage: {
    flexWrap: 'wrap',
    justifyContent: 'start',
    overflow: 'hidden',
  },
  title: {
    color: theme.palette.secondary.contrastText,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%, rgba(0,0,0,0) 100%)',
  },
}));

function DocumentControl(props) {
  const {
    events = {},
    label,
    name: controlName,
    placeholder,
    onChange,
    settings: {
      readOnly = false,
      route = 'tmp/',
    } = {},
  } = props;
  let { value = [] } = props;

  if (typeof value === 'string') {
    try {
      value = JSON.parse(value) || [];
    } catch (e) {
      value = [];
    }
  }

  const classes = useStyles();
  const [modal, setModal] = useState();
  const [open, setOpen] = useState();
  const [documents, setDocuments] = useState(value || []);
  const [loading, setLoading] = useState(false);
  const [nameFile, setNameFile] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [signedDocuments, setSignedDocuments] = useState([]);

  const handlePreview = async function (document) {
    const signedUrl = await getSignedUrl(document.url, 30);
    const extension = (document.url.split('.').pop() || '').toLowerCase();
    const mimeType = MIME_TYPES.find(_ => _.name === extension);

    setModal({
      canFullScreen: true,
      data: {
        ...document,
        mimeType,
        signedUrl,
      },
      download: true,
      open: true,
      title: 'Vista previa',
    });
  };

  const handleShowModal = (item) => {
    setNameFile('');
    setFilesToUpload([]);

    setModal({
      data: {},
      download: false,
      item,
      open: true,
      title: 'Cargar archivo',
    });
  };

  const handleFileSave = async () => {
    setLoading(true);
    const uploaded = [];

    for await (const fileToUpload of filesToUpload) {
      const [, ext] = fileToUpload.name.split('.');
      const s3File = await upload(
        `${route}${new Date().getTime()}.${String(Math.random()).substring(
          2,
          7,
        )}${ext ? `.${ext.toLowerCase()}` : ''}`,
        fileToUpload,
      );

      uploaded.push({
        active: true,
        name: `${nameFile || ''}${filesToUpload.length > 1 ? (` ${uploaded.length + 1}`) : ''}`,
        uploaded: new Date().getTime(),
        url: s3File.Location,
      });
    };

    setDocuments([
      ...documents,
      ...uploaded,
    ]);
    setModal({
      open: false,
    });
  };

  const handleShowModalDelete = (index) => {
    setOpen({
      index,
      show: true,
    });
  };

  const handleDelete = (index) => {
    setOpen({
      index,
      show: false,
    });
    const tmpImg = [...documents.filter(_ => _.active)];
    tmpImg[index].active = false;

    setDocuments(tmpImg);
  };

  const handleDownload = async function (image) {
    const url = await getSignedUrl(image.url);

    window.open(url);
  };

  const loadSigned = async () => {
    const tmpDoc = [];
    if (documents?.length > 0) {
      for await (const document of documents.filter(_ => _.active)) {
        const signedUrl = await getSignedUrl(document.url);
        tmpDoc.push(signedUrl);
      }
      setSignedDocuments(tmpDoc);
    }
  };

  useEffect(() => {
    const target = {
      name: controlName,
      value: documents,
    };

    if (documents.length > 0) {
      onChange({
        target,
      });
    }

    if (events?.onChange) {
      events.onChange(target);
    }

    loadSigned();
    setLoading(false);
  }, [documents]);

  return (
    <>
      <Dialog
        open={open?.show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'¿Esta seguro de eliminar el archivo?'}
        </DialogTitle>
        <DialogActions>
          <Button
            color="secondary"
            children={'Cancelar'}
            onClick={() => setOpen({
              show: false,
            })}
            variant="contained"
          />
          <Button
            autoFocus
            color="primary"
            children={'Aceptar'}
            onClick={() => handleDelete(open?.index)}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
      <Modal
        canFullScreen={modal?.canFullScreen}
        open={modal?.open}
        title={modal?.title}
        events={{
          onClose: () => setModal(),
        }}
        actions={[
          {
            color: 'secondary',
            fn: () => handleDownload(modal?.data),
            hidden: !modal?.download,
            icon: <CloudDownloadIcon />,
            label: 'Descargar',
          },
          {
            color: 'secondary',
            disabled: !filesToUpload.length || loading || !nameFile,
            fn: handleFileSave,
            hidden: modal?.download,
            icon: loading ? <CircularProgress size={20} /> : <CloudUploadIcon />,
            label: 'Cargar',
          },
        ]}
      >

        {modal?.data?.mimeType?.visor === 'image' && (
          <img alt="" width="100%" src={modal?.data?.signedUrl} />
        )}

        {modal?.data?.mimeType?.visor === 'document' && (
          <iframe
            frameborder="0"
            height="100%"
            src={`https://docs.google.com/gview?url=${encodeURIComponent(modal?.data?.signedUrl)}&embedded=true`}
            title="Visor"
            width="100%"
          />
        )}
        {modal?.data?.mimeType?.visor === 'code' && (<>
          Vista previa no disponible
        </>)}

        <Grid container>
          <input
            multiple
            id={`file_${modal?.item?.field}`}
            name={modal?.item?.filename}
            type="file"
            style={{
              display: 'none',
            }}
            onChange={({ target }) => {
              const count = target.files.length;
              const files = [];
              for (let i = 0; i < count; i++) {
                files.push(target.files[i]);
              }

              setFilesToUpload([
                ...filesToUpload,
                ...files,
              ]);
            }}
          />
          <Grid item md={11}>
            <TextField
              error={!nameFile}
              fullWidth={true}
              label={'Nombre del archivo'}
              name={'file_name'}
              onChange={({ target }) => setNameFile(target.value)}
              placeholder={'Nombre del archivo'}
              required
              size='small'
              type={'text'}
              value={nameFile}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <IconButton
              color="primary"
              disabled={loading}
              onClick={() => {
                document.getElementById(`file_${modal?.item?.field}`).click();
              }}
              size="small"
              variant="contained"
            >
              <Tooltip
                children={<SvgIcon
                  children={<PostAddIcon />}
                  fontSize="large"
                />}
                title="Agregar archivos"
              />
            </IconButton>
          </Grid>

          <Grid item md={12}>
            <List dense>
              {filesToUpload.map((fileItem, fileIndex) => (<ListItem
                button
                key={fileIndex}
              >
                <ListItemAvatar
                  children={<Avatar children={<InsertDriveFileIcon />} />}
                />
                <ListItemText
                  primary={fileItem.name}
                  secondary={<>
                    <b>{Math.round(fileItem.size / 10.24, 2) / 100} KB</b>
                    {' '}{fileItem.type}
                  </>}
                />
                {!loading && (<ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <CancelIcon
                      onClick={() => {
                        setFilesToUpload(filesToUpload.filter((_, i) => i !== fileIndex));
                      }}
                      style={{
                        color: red[500],
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>)}
              </ListItem>))}
            </List>
          </Grid>
        </Grid>

      </Modal>

      <div className={classes.rootImage}>
        <fieldset style={{
          borderRadius: 5,
        }}>
          {label && <legend style={{
            marginLeft: 8,
          }}>
            <Typography color="textSecondary" variant="caption">
              <Box style={{
                marginLeft: 5,
                marginRight: 5,
              }}>
                {label}
              </Box>
            </Typography>
          </legend>}
          {placeholder && <Typography color="textPrimary" variant="caption">
            <Box
              children={placeholder}
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            />
          </Typography>}
          <ImageList
            alignItems="center"
            className={classes.imageList}
            cols={5}
            direction="row"
            justifyContent="center"
          >
            {!readOnly && <IconButton
              className={classes.button}
              onClick={() => handleShowModal(value)}
            >
              <Tooltip
                children={<SvgIcon children={<AddIcon />} />}
                title="Agregar archivo"
              />
            </IconButton>}
            {
              documents.filter(_ => _.active).map((doc, index) => {
                const ext = doc.url.split(/[\s.]+/).pop();

                return (
                  <ImageListItem key={doc.name} style={{
                    cursor: 'pointer',
                    minWidth: 120,
                    padding: 5,
                  }}>
                    {EXT_IMAGES.includes(ext) ?
                      (<img
                        alt={doc.name}
                        src={signedDocuments[index]}
                        onClick={() => handlePreview(doc)}
                      />) :
                      (<SvgIcon
                        fontSize="large"
                        children={<InsertDriveFileIcon />}
                        onClick={() => handlePreview(doc)}
                        style={{
                          minHeight: 126,
                          width: '100%',
                        }}
                      />)
                    }
                    <ImageListItemBar
                      title={doc.name}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={readOnly
                        ? undefined
                        : (<IconButton
                          aria-label={`star ${doc.name}`}
                          onClick={() => handleShowModalDelete(index)}
                        >
                          <DeleteIcon className={classes.title} />
                        </IconButton>)}
                    />
                  </ImageListItem>
                );
              })
            }
          </ImageList>
        </fieldset>
      </div>
    </>
  );
}

DocumentControl.defaultProps = {
  onChange: () => { },
  settings: {},
  value: [],
};

DocumentControl.propTypes = {
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  settings: PropTypes.shape({
    readOnly: PropTypes.bool,
    route: PropTypes.string,
  }),
  value: PropTypes.array,
};

export default DocumentControl;
