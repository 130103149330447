import { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Link as RouterLink,
  useLocation,
  matchPath,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  // Payment as PaymentIcon,
  Person as PersonIcon,
} from '@material-ui/icons';

import Logo from 'components/Logo';

import NavItem from './NavItem';

function renderNavItems({ items, permissions, ...rest }) {
  items = items.filter(_ => _.forceShow || permissions[_.name]);

  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          permissions,
          ...rest,
        }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, permissions, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          permissions,
          items: item.items.filter(_ => !_.hidden),
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  container_menu: {
    backgroundColor: '#173184',
    boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.25)',
  },
  menu_item: {
    color: '#FFFFFF !important',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    height: 'calc(100% - 64px)',
    top: 64,
    width: 256,
  },
  desktopMiniDrawer: {
    height: 'calc(100% - 64px)',
    top: 50,
    width: 60,
  },
  avatar: {
    cursor: 'pointer',
    height: 64,
    width: 64,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
}));

function NavBar({ openMobile, openMenu, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const navConfig = [
    {
      subheader: '',
      items: [
        {
          forceShow: true,
          href: '/',
          icon: DashboardIcon,
          name: 'dashboard',
          title: 'Dashboard',
        },
        {
          forceShow: true,
          href: '/profile',
          icon: PersonIcon,
          name: 'dashboard',
          title: 'Perfil',
        },
        // {
        //   forceShow: true,
        //   href: '/payments',
        //   icon: PaymentIcon,
        //   name: 'payments',
        //   title: 'Pago de servicios',
        // },
      ],
    },
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{
        suppressScrollX: true,
      }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo
                className={classes.logo}
                variant="imagotipo-horizontal"
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader
                  children={config.subheader}
                  disableGutters
                  disableSticky
                />
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        />
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes[openMenu
              ? 'desktopDrawer'
              : 'desktopMiniDrawer'],
          }}
          open={openMenu}
          variant="persistent"
        />
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMenu: PropTypes.bool,
};

export default NavBar;
