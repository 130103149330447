import {
  GET_COMPANY,
  UPDATE_WEBSOCKET,
} from 'redux/constants/system';
import Dynamicore, { SERVICES } from 'services/dynamicore';

const { COMPANY: COMPANY_SERVICE } = SERVICES;

const getData = async (service, filters = {}) => {
  const {
    total = 0,
    values: items = [],
  } = await Dynamicore(service).get(filters);

  return {
    items,
    total,
  };
};

const getCompany = function () {
  return async (dispatch) => {
    const { items = [] } = await getData(COMPANY_SERVICE);
    const [company] = items;

    dispatch({
      type: GET_COMPANY,
      payload: {
        company,
      },
    });
  };
};

const updateWebSocket = function (socketMessage) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_WEBSOCKET,
      payload: {
        socketMessage,
      },
    });
  };
};

export {
  getCompany,
  updateWebSocket,
};
