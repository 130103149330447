import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es';

import {
  Auth,
  ScrollReset,
  WssProvider,
} from 'components';
import Routes from 'routes';
import { createTheme } from 'theme';

const history = createBrowserHistory();
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

function App() {
  const { data: { company: publicFormCompany } = {} } = useSelector(({ publicForms }) => publicForms);

  useStyles();

  return (
    <ThemeProvider theme={createTheme({
      ...publicFormCompany.theme, 
    })}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <WssProvider />
                <ScrollReset />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
