import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  alpha,
  Button,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { DynamicForm } from 'components';
import { TABLES_ITEMS_PER_PAGE } from 'settings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filterCard: {
    marginBottom: theme.spacing(2),
  },
  primary: {
    color: theme.palette.primary.main,
  },
  primaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  secondaryBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  errorBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.7),
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  successBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.7),
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  warningBg: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.7),
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function DataTable(props) {
  const {
    actions,
    className,
    filters,
    footers,
    getDataAction,
    headers,
    rows,
    pagination = {},
    searchAction,
    selectable,
    size,
    stickyHeader,
    subTitle,
    title,
    total = 0,
    offsetTop,
  } = props;
  const { items = rows } = props;

  const {
    disabled: removePagination = false,
    perPageOptions = TABLES_ITEMS_PER_PAGE,
  } = pagination;
  const history = useHistory();
  const classes = useStyles();
  const [limit, setLimit] = useState(perPageOptions[0]);
  const [maxHeight, setMaxHeight] = useState(250);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const containerRef = useRef();

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked
      ? items.map((item) => item.id)
      : []);
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId),
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setQuery('');
    setPage(newPage + 1);
    getDataAction({
      page: newPage + 1,
      limit,
    });
  };

  const handleLimitChange = (event) => {
    setQuery('');
    const newLimit = event.target.value;
    setLimit(newLimit);
    setPage(1);
    getDataAction && getDataAction({
      page: 1,
      limit: newLimit,
    });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    searchAction(event.target.value);
  };

  const selectedSomeItems =
    selectedItems &&
    selectedItems.length > 0 &&
    selectedItems.length < items &&
    items.length;
  const selectedAllItems =
    selectedItems && selectedItems.length === items && items.length;

  useEffect(() => {
    return history.listen(() => {
      setPage(1);
    });
  }, [history]);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const { top: elementTop } = containerRef.current.getBoundingClientRect();
    const height = windowHeight - elementTop - offsetTop;

    setMaxHeight(height);
  }, []);

  return (
    <div className={clsx(classes.root, className)}>

      {filters?.fields?.length > 0 && (
        <Paper className={classes.filterCard}>
          <DynamicForm
            initialValues={filters.values}
            fields={filters.fields}
          />
        </Paper>
      )}

      {!!searchAction && <Paper className={classes.filterCard}>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      children={<SearchIcon />}
                      color="action"
                      fontSize="small"
                    />
                  </InputAdornment>
                ),
              }}
              label="Buscar"
              name="query"
              onChange={handleQueryChange}
              placeholder="Buscar..."
              size="small"
              variant="outlined"
              value={query}
            />
          </Grid>
        </Grid>

      </Paper>}

      <Card>
        {title && (<>
          <CardHeader title={title} subheader={subTitle} />
          <Divider />
        </>)}
        <PerfectScrollbar>
          <Box>
            <Paper className={classes.root}>
              <TableContainer
                ref={containerRef}
                style={{
                  maxHeight: stickyHeader ? maxHeight : undefined,
                }}
              >
                <Table stickyHeader={stickyHeader} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {selectable && (
                        <TableCell padding="checkbox" size={size}>
                          <Checkbox
                            checked={selectedAllItems}
                            indeterminate={selectedSomeItems}
                            onChange={handleSelectAllItems}
                          />
                        </TableCell>
                      )}
                      {headers.map((head, index) => {
                        let align = 'center';
                        let cellValue = head;

                        if (typeof head === 'object') {
                          cellValue = head.label;

                          if (head.type === 'number') {
                            align = 'right';
                          }
                        }

                        return (<TableCell
                          align={align}
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                      {actions && (<TableCell
                        align="center"
                        style={{
                          minWidth: `${actions.length * 2 + 2}em`,
                        }}
                        children="Acciones"
                        size={size}
                      />)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items && items.map((item, index) => {
                      const isItemSelected = selectedItems.includes(item.id);

                      return (
                        <StyledTableRow
                          key={index}
                        >
                          {selectable && (
                            <TableCell padding="checkbox" size={size}>
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleSelectOneItem(event, item.id)
                                }
                                value={isItemSelected}
                              />
                            </TableCell>
                          )}

                          {item.values.map((colValue, iCell) => {
                            let cell = {
                              children: colValue,
                            };

                            if (typeof colValue === 'object' && colValue !== null && !colValue.props) {
                              if (colValue.field) {
                                cell.children = (<DynamicForm
                                  fields={colValue.field}
                                  size="small"
                                />);
                              } else {
                                cell = {
                                  align: colValue.align || 'center',
                                  children: colValue.label,
                                };
                              }
                            }

                            return (
                              <TableCell
                                align="center"
                                style={item?.data?.style}
                                key={iCell}
                                size={size}
                                {...cell}
                              />
                            );
                          })}

                          {Array.isArray(actions) && (
                            <TableCell
                              align="center"
                              size={size}
                              style={item?.data?.style}
                            >
                              {actions.map((action, key) => {
                                const { fn = () => { } } = action;
                                let {
                                  color,
                                  disabled,
                                  hidden,
                                  icon,
                                  title,
                                } = action;

                                if (typeof disabled === 'function') {
                                  disabled = disabled(item, index);
                                }

                                if (disabled) {
                                  color = 'default';
                                } else if (typeof color === 'function') {
                                  color = color(item, index);
                                }

                                if (typeof hidden === 'function') {
                                  hidden = hidden(item, index);
                                }

                                if (typeof icon === 'function') {
                                  icon = icon(item, index);
                                }

                                if (typeof title === 'function') {
                                  title = title(item, index);
                                }

                                const Title = title ? Tooltip : Fragment;
                                return !hidden ? (
                                  <Title key={key} title={title}>
                                    {action.label
                                      ? (<Button
                                        children={action.label}
                                        color={action.color}
                                        disabled={disabled}
                                        onClick={() => fn(item, index)}
                                        size="small"
                                        startIcon={action.icon}
                                        variant="contained"
                                      />)
                                      : (<IconButton
                                        disabled={disabled}
                                        onClick={() => fn(item, index)}
                                        size="small"
                                        variant="outlined"
                                      >
                                        <SvgIcon
                                          children={icon}
                                          className={clsx(classes[color])}
                                          fontSize="small"
                                        />
                                      </IconButton>)}
                                  </Title>) : null;
                              })}
                            </TableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                  {footers && (<TableFooter>
                    <TableRow>
                      {footers.map((footer, index) => {
                        let align = footer.align || 'center';
                        let cellValue = footer;

                        if (typeof footer === 'object') {
                          cellValue = footer.label || null;

                          if (footer.type === 'number') {
                            align = 'right';
                          }
                        }

                        return (<TableCell
                          align={align}
                          children={cellValue}
                          key={index}
                          size={size}
                        />);
                      })}
                    </TableRow>
                  </TableFooter>)}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </PerfectScrollbar>

        {!removePagination && (
          <TablePagination
            component="div"
            count={total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={perPageOptions}
          />
        )}
      </Card>
    </div >
  );
}

DataTable.defaultProps = {
  headers: [],
  offsetTop: 76,
  size: 'small',
  stickyHeader: true,
};

DataTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOfType([
      PropTypes.oneOf(['error', 'primary', 'secondary']),
      PropTypes.func,
    ]),
    disabled: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    fn: PropTypes.func,
    hidden: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
    label: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
  })),
  className: PropTypes.any,
  filters: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    values: PropTypes.array,
  }),
  footers: PropTypes.array,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array,
  rows: PropTypes.array,
  getDataAction: PropTypes.func,
  searchAction: PropTypes.func,
  selectable: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  offsetTop: PropTypes.number,
  pagination: PropTypes.shape({
    disabled: PropTypes.bool,
    perPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  stickyHeader: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default DataTable;
