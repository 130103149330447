import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';

import { getCurrentSessionData } from 'utils/aws/cognito';

function AuthGuard({ children }) {
  const { accessToken } = getCurrentSessionData();

  if (!accessToken || jwtDecode(accessToken).exp * 1000 < new Date().getTime()) {
    const { hash = '', pathname, search = '' } = window.location;
    const redirect = Buffer
      .from(`${pathname}${search}${hash}`)
      .toString('base64');

    return <Redirect to={`/auth/register?redirect=${redirect}`} />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
