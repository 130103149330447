import { lazy } from 'react';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const publicRoutes = {
  path: '/public',
  routes: [
    {
      component: lazy(() => import('views/Public/DigitalAuthentication')),
      exact: true,
      path: '/public/digital-authentication',
    },
    {
      component: lazy(() => import('views/Public/Result')),
      exact: true,
      path: '/public/result',
    },
    error404,
  ],
};

export default publicRoutes;
