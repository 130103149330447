import moment from 'moment';
import numeral from 'numeral';

import {
  FORMAT_DATE,
  FORMAT_DATETIME,
  FORMAT_NUMBER,
} from 'settings';

function dateTimeFormat(value, format = FORMAT_DATETIME) {
  return value && moment(value).isValid()
    ? moment(value).format(format)
    : '';
}

function dateFormat(value, format = FORMAT_DATE) {
  return value && moment(value).isValid()
    ? moment(value).format(format)
    : '';
}

function numberFormat(value = 0, prefix = null) {
  return numeral(value)
    .format(`${prefix ? prefix.concat(' ') : ''}${FORMAT_NUMBER}`);
}

export {
  dateFormat,
  dateTimeFormat,
  numberFormat,
};
