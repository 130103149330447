import { lazy } from 'react';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const authenticationRoutes = {
  path: '/auth',
  routes: [
    {
      component: lazy(() => import('views/Authentication/SignIn')),
      exact: true,
      path: '/auth/login',
    },
    {
      component: lazy(() => import('views/Authentication/RecoveryView')),
      exact: true,
      path: '/auth/recovery',
    },
    {
      component: lazy(() => import('views/Authentication/SignUp')),
      exact: true,
      path: '/auth/register',
    },
    error404,
  ],
};

export default authenticationRoutes;
