import { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import {
  DynamicForm,
  Modal,
} from 'components';
import { changePasswordSchema } from 'validations/schema/forms';

function UpdatePasswordModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [showPasswords, setShowPasswords] = useState();
  const { t } = useTranslation();

  const actions = [
    {
      color: 'primary',
      form: 'frmUpdateUserPassword',
      label: 'Guardar',
      type: 'submit',
    },
  ];

  const handleSubmitData = async function (values) {
    const {
      oldPassword,
      newPassword,
    } = values;
    let message, variant = 'success';

    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      message = 'Contraseña de usuario actualizada';

      if (props.events?.onClose) {
        props.events.onClose();
      }
    } catch (error) {
      message = `auth.${error.code || 'CanNotChangePassword'}`;
      variant = 'error';
    } finally {
      enqueueSnackbar(t(message), {
        variant, 
      });
    }
  };

  return (<Modal actions={actions} maxWidth="xs" {...props}>
    <DynamicForm
      fields={[
        {
          label: 'Contraseña actual',
          name: 'oldPassword', 
        },
        {
          label: 'Contraseña nueva',
          name: 'newPassword', 
        },
      ].map(field => ({
        display: {
          endIcon: showPasswords?.[field.name]
            ? <VisibilityOffIcon />
            : <VisibilityIcon />,
          endIconAction: () => setShowPasswords({
            ...showPasswords,
            [field.name]: !showPasswords?.[field.name],
          }),
        },
        label: field.label,
        name: field.name,
        type: showPasswords?.[field.name]
          ? 'text'
          : 'password',
      }))}
      id="frmUpdateUserPassword"
      handleSubmitData={handleSubmitData}
      validationSchema={changePasswordSchema}
    />
  </Modal>);
}

UpdatePasswordModal.defaultProps = {
  events: {},
  open: false,
  title: 'Cambiar contraseña',
};

UpdatePasswordModal.propTypes = {
  events: PropTypes.shape({
    onClose: PropTypes.func,
  }),
  open: PropTypes.bool,
  title: PropTypes.string,
};

export default UpdatePasswordModal;
