import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  withWidth,
} from '@material-ui/core';

import { Divider } from 'components';
import { pickValue } from 'utils/objects';

import {
  CaptchaControl,
  CardSelector,
  CodeControl,
  ColorControl,
  DateControl,
  DocumentControl,
  FileControl,
  HtmlControl,
  JsonControl,
  KeyValueControl,
  ListControl,
  NumberControl,
  SelectControl,
  SliderControl,
  SwitchControl,
  TextControl,
  ButtonControl,
} from './controls';

const CONTROL = {
  CAPTCHA: 'captcha',
  CARDSELECTOR: 'cardselector',
  CHECKBOX: 'checkbox',
  CODE: 'code',
  COLOR: 'color',
  CRON: 'cron',
  DATE: 'date',
  DOCUMENT: 'document',
};

const CONTROLS = {
  [CONTROL.CAPTCHA]: CaptchaControl,
  [CONTROL.CARDSELECTOR]: CardSelector,
  [CONTROL.CODE]: CodeControl,
  [CONTROL.COLOR]: ColorControl,
  [CONTROL.CHECKBOX]: SwitchControl,
  [CONTROL.DATE]: DateControl,
  divider: '', // FIXME: Delete type
  [CONTROL.DOCUMENT]: DocumentControl,
  email: TextControl,
  file: FileControl,
  hidden: TextControl,
  html: HtmlControl,
  json: JsonControl,
  keyvalue: KeyValueControl,
  list: ListControl,
  number: NumberControl,
  password: TextControl,
  select: SelectControl,
  slider: SliderControl,
  switch: SwitchControl,
  text: TextControl,
  textarea: TextControl,
};

function getControl({ type }) {
  return CONTROLS[type] || TextControl;
}

function DynamicForm(props) {
  const {
    actions,
    fields,
    handleSubmitData,
    id,
    initialValues,
    spacing,
    validationSchema,
    width,
  } = props;

  async function onSubmit(values, formikHelpers) {
    const {
      // resetForm,
      setErrors,
      setStatus,
      setSubmitting,
    } = formikHelpers;

    try {
      setSubmitting(true);
      await handleSubmitData(validationSchema.cast(values));

      setStatus({
        success: true,
      });
    } catch (error) {
      setStatus({
        success: false,
      });
      setErrors({
        submit: error.message,
      });
    } finally {
      setSubmitting(false);
    }
  }

  if (!Array.isArray(fields)) {
    const Control = getControl(fields);

    return <Control
      {...fields}
      size={props.size}
    />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          // isSubmitting,
          setFieldTouched,
          // setFieldValue,
          touched,
          values,
        } = formikProps;

        const onSubmitForm = function (e) {
          handleSubmit(e);

          fields.forEach(f => {
            if (f.name) {
              setFieldTouched(f.name, true);
            }
          });
        };

        return (
          <Form onReset={handleReset} onSubmit={onSubmitForm} id={id}>
            {/* <pre>{JSON.stringify(errors, null, '  ')}</pre> */}
            <Grid container spacing={spacing}>
              {fields
                .map((item, index) => {
                  const Control = getControl(item);
                  const { display: { size = props.size } = {} } = item;

                  let {
                    display: {
                      breakpoints = {
                        md: 12,
                      },
                    } = {},
                  } = item;

                  switch (item.type) {
                    case CONTROL.CAPTCHA:
                    case CONTROL.CARDSELECTOR:
                    case CONTROL.DOCUMENT:
                      breakpoints = {
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xl: 12,
                        xs: 12,
                      };
                      break;

                    default:
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'actions') {
                    return (<Grid key={index} item {...breakpoints}>
                      {item?.settings?.options.map((option, index) => (
                        <IconButton
                          children={option.icon}
                          disabled={option.disabled}
                          key={index}
                          size="small"
                          onClick={option.fn}
                        />
                      ))}
                    </Grid>);
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'description') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Typography
                        align="justify"
                        children={item.label}
                      />
                    </Grid>);
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'link') {
                    return (<Grid key={index} item {...breakpoints}>
                      <Link
                        children={item.label}
                        color={item.color}
                        component={RouterLink}
                        to={item.to}
                        variant="body2"
                      />
                    </Grid>);
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'btn_link_submit') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Button
                        style={item.style}
                        color={item.color}
                        disabled={item.processing}
                        fullWidth
                        size="large"
                        type={'submit'}
                        variant="contained"
                        onClick={() => item.fn()}
                      >
                        {item.processing && (<CircularProgress
                          size={18}
                          style={{
                            marginRight: 5,
                          }}
                        />)}
                        {item.label}
                      </Button>
                    </Grid>);
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'space') {
                    return (<Grid key={index} item {...breakpoints}>
                      <div style={{
                        height: `${width === 'md' || width === 'lg' ? item.height : 0}px`,
                      }}>&nbsp;</div>
                    </Grid>);
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'offset') {
                    return breakpoints[width] !== 12 ? (<Grid
                      item
                      key={index}
                      {...breakpoints}
                    />) : null;
                  }

                  // FIXME: Eliminar, pasar a usar type: 'component'
                  if (item.type === 'divider') {
                    return (<Grid
                      children={<Divider children={item.label} />}
                      key={index}
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    />);
                  }

                  return item.type === 'hidden'
                    ? (<></>)
                    : (<Grid key={`${item.name || ''}_grid_${index}`} item {...breakpoints}>
                      <Control
                        {...item}
                        key={`${item.name || ''}_${index}`}
                        error={pickValue(touched, item.name) && pickValue(errors, item.name)}
                        helperText={pickValue(touched, item.name) ? pickValue(errors, item.name) : null}
                        size={size}
                        value={pickValue(values, item.name)}
                        onBlur={handleBlur}
                        onChange={async (event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>);
                })}
            </Grid>

            {actions && actions
              .filter(_ => !_.hidden)
              .map((action, index) => (
                <Box key={index} mt={2}>
                  {/* FIXME: Eliminar, pasar a usar type: 'component' */}
                  {action.typeButton === 'login' ? (
                    <ButtonControl
                      label={action.label}
                      color={action.color}
                      disabled={action.processing}
                      fullWidth
                      size="large"
                      type={'submit'}
                      variant="contained"
                    />
                  ) : (
                    <Button
                      color={action.color}
                      disabled={action.processing}
                      fullWidth
                      size="large"
                      type={action.type}
                      variant="contained"
                    >
                      {action.processing && (<CircularProgress
                        size={18}
                        style={{
                          marginRight: 5,
                        }}
                      />)}
                      {action.label}
                    </Button>
                  )}
                </Box>
              ))}

          </Form>
        );
      }}
    </Formik>
  );
}

const propFields = PropTypes.shape({
  display: PropTypes.shape({
    breakpoints: PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xl: PropTypes.number,
      xs: PropTypes.number,
    }),
    size: PropTypes.oneOf([
      'small',
      'medium',
    ]),
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(CONTROLS)),
  settings: PropTypes.shape({
    emptyElement: PropTypes.string,
    format: PropTypes.oneOf([
      'clabe',
      'credit_card',
      'credit_card_expire_time',
      'currency',
      'mx_phone',
      'percentage',
    ]),
    max: PropTypes.number,
    min: PropTypes.number,
    multiline: PropTypes.bool,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    step: PropTypes.number,
  }),
});

DynamicForm.defaultProps = {
  fields: [],
  handleSubmitData: () => { },
  id: `dynamic_form_${Math.floor(Math.random() * 10000)}`,
  initialValues: {},
  spacing: 2,
  size: 'small',
  validationSchema: Yup.object(),
};

DynamicForm.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary']),
    hidden: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.oneOf(['submit']),
    processing: PropTypes.bool,
  })),
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(propFields),
    propFields,
  ]),
  handleSubmitData: PropTypes.func,
  id: PropTypes.string,
  initialValues: PropTypes.object,
  spacing: PropTypes.number,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  validationSchema: PropTypes.object,
  width: PropTypes.string,
};

export default withWidth()(DynamicForm);
