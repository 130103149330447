import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const creditRoutes = {
  guard: AuthGuard,
  path: '/loan',
  routes: [
    {
      exact: true,
      path: '/loan/external-processing',
      component: lazy(() => import('views/Loan/ExternalProcessing')),
    },
    {
      exact: true,
      path: '/loan/request',
      component: lazy(() => import('views/Loan/Request')),
    },
    {
      exact: true,
      path: '/loan/result',
      component: lazy(() => import('views/Loan/Result')),
    },
    {
      exact: true,
      path: '/loan/verification',
      component: lazy(() => import('views/Loan/Verification')),
    },
    error404,
  ],
};

export default creditRoutes;
