import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { w3cwebsocket } from 'websocket';

import { updateWebSocket } from 'redux/actions/system';
import { WEBSOCKET_URL } from 'settings';
import { getCurrentSessionData } from 'utils/aws/cognito';

function WssProvider() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = getCurrentSessionData();
  const wssClient = new w3cwebsocket(`${WEBSOCKET_URL}/user?token=${accessToken}`);

  useEffect(() => {
    wssClient.onopen = () => {
      console.debug('WebSocket client: Connected');
    };

    wssClient.onmessage = ({ data }) => {
      const message = JSON.parse(data);
      let showAlert = true;

      dispatch(updateWebSocket(message));

      switch (message.type) {
        default: break;

        case 'public_digital_authentication': {
          showAlert = false;
          if (window.location.pathname === '/loan/verification') {
            window.location.href = '/loan/external-processing';
          }

          break;
        }

        case 'public_external_processing': {
          showAlert = false;
          if (window.location.pathname === '/loan/external-processing') {
            window.location.href = '/loan/result';
          }

          break;
        }
      }

      if (showAlert) {
        enqueueSnackbar(message.name, {
          autoHideDuration: 10000,
          variant: message.level || 'default',
        });
      }
    };

    wssClient.onclose = () => {
      console.debug('WebSocket client: Disconnected');
    };

    return () => wssClient.close();
  }, []);

  return (<></>);
}

export default WssProvider;
