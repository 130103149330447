import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  CircularProgress,
  Button,
  Grid,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import Breadcrumb from './Breadcrumb';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginRight: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Header(props) {
  const {
    actions,
    breadcrumb,
    breadcrumbPieces,
    className,
    title,
  } = props;
  const classes = useStyles();
  const actionRef = useRef(null);
  const [openMenu, setOpenMenu] = useState({});
  const [menuValue, setMenuValue] = useState({});

  const handleClose = function (index) {
    setOpenMenu({
      ...openMenu,
      [`actions_menu_${index}`]: false,
    });
  };

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
    >
      <Grid item>
        {breadcrumb && <Breadcrumb
          pieces={breadcrumbPieces}
        />}
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
      </Grid>
      {!!actions.length && <Grid item>
        {actions.map((item, index) => {
          let component;
          const {
            processing = false,
            disabled = processing || false,
          } = item;

          if (item.type === 'menu') {
            if (!menuValue[`actions_menu_${index}`]) {
              const selected = item.options.find(({ selected }) => selected);

              setMenuValue({
                ...menuValue,
                [`actions_menu_${index}`]: selected,
              });
            }

            component = (<>
              <Button
                ref={actionRef}
                onClick={() => setOpenMenu({
                  ...openMenu,
                  [`actions_menu_${index}`]: true,
                })}
              >
                <SvgIcon
                  children={item.icon}
                  className={classes.actionIcon}
                  fontSize="small"
                />
                {menuValue[`actions_menu_${index}`]
                  ? menuValue[`actions_menu_${index}`].label
                  : ''}
              </Button>
              <Menu
                anchorEl={actionRef.current}
                onClose={() => handleClose(index)}
                open={openMenu[`actions_menu_${index}`]}
                PaperProps={{
                  className: classes.menu, 
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {item.options.map((option, i) => (
                  <MenuItem
                    children={option.label}
                    key={i}
                    onClick={() => {
                      setMenuValue({
                        ...menuValue,
                        [`actions_menu_${index}`]: option,
                      });
                      handleClose(index);

                      if (typeof option.fn === 'function') {
                        option.fn(option);
                      }
                    }}
                  />
                ))}
              </Menu>
            </>);
          } else {
            component = (
              <Button
                children={item.label}
                color={item.color || 'secondary'}
                disabled={disabled || processing}
                key={index}
                form={item.form}
                onClick={item.fn}
                startIcon={processing ?
                  (<CircularProgress
                    color="secondary"
                    size={18}
                    style={{
                      marginRight: 5, 
                    }}
                  />)
                  : item.icon}
                style={{
                  ...{
                    marginLeft: 5,
                    marginRight: 5, 
                  },
                  display: (item.hidden ? 'none' : undefined),
                  ...(item.color === 'error' && !disabled ? {
                    color: 'white',
                    backgroundColor: red[500],
                    '&:hover': {
                      backgroundColor: red[900],
                    },
                  } : {}),
                }}
                type={item.type}
                variant={item.variant || 'contained'}
              />
            );
          }

          return component;
        })}
      </Grid>}
    </Grid>
  );
}

Header.defaultProps = {
  actions: [],
  breadcrumb: true,
};

Header.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf(['error', 'default', 'primary', 'secondary']),
    disabled: PropTypes.bool,
    fn: PropTypes.func,
    form: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    type: PropTypes.oneOf(['button', 'menu', 'submit']),
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  })),
  breadcrumb: PropTypes.bool,
  breadcrumbPieces: PropTypes.array,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Header;
