import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Slider, Typography,
} from '@material-ui/core';

function Fieldset({ label, children }) {
  return (
    <fieldset style={{
      borderRadius: 5,
    }}>
      <legend style={{
        marginLeft: 8,
      }}>
        <Typography color="textSecondary" variant="caption">
          <Box style={{
            marginLeft: 5,
            marginRight: 5,
          }}>
            {label}
          </Box>
        </Typography>
      </legend>
      <Box style={{
        marginLeft: 15,
        marginRight: 15,
      }}>
        {children}
      </Box>
    </fieldset>);
}

function SliderControl(props) {
  const {
    events = {},
    label,
    name,
    value,
    settings = {},
    onChange,
  } = props;
  const {
    max = 100,
    marks = true,
    min = 0,
    step = max / 10,
  } = settings;

  const Container = label
    ? Fieldset
    : Fragment;

  const handleSliderChange = (event, value) => {
    const target = {
      name,
      value,
    };

    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (
    <Container label={label}>
      <Slider
        aria-labelledby="discrete-slider"
        defaultValue={value}
        max={max}
        marks={marks}
        min={min}
        step={step}
        valueLabelDisplay="auto"
        onChange={handleSliderChange}
      />
    </Container>
  );
}

Fieldset.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element,
};

SliderControl.defaultProps = {
  value: 0,
  onChange: () => { },
  onBlur: () => { },
};

SliderControl.propTypes = {
  events: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  settings: PropTypes.shape({
    max: PropTypes.number,
    marks: PropTypes.bool,
    min: PropTypes.number,
    step: PropTypes.number,
  }),
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default SliderControl;
