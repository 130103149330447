import { cardExpiry } from './functions';

const FORMATS = {
  CODE: '# # # # # #',
  CLABE: '### ### ########### #',
  CREDIT_CARD: '#### #### #### ####',
  CREDIT_CARD_EXPIRE_TIME: cardExpiry,
  MX_PHONE: '(+52) ### ### ## ##',
};

export default FORMATS;
