import {
  forwardRef, useEffect, useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
} from '@material-ui/core';

import { SITE_TITLE } from 'settings';
import track from 'utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cronInput: {
    marginTop: theme.spacing(2),
  },
}));

const Page = forwardRef(({ children, favicon, maxWidth, siteTitle, title, ...rest }, ref) => {
  const classes = useStyles();
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  useEffect(() => {
    const { REACT_APP_STAGE: APP_STAGE } = process.env;
    const stage = APP_STAGE !== 'production'
      ? ` (${APP_STAGE})`
      : '';

    document.title = `${title ? `${title} - ` : ''}${siteTitle}${stage}`;
    if (favicon) {
      document.querySelector('link[rel="icon"]').href = favicon;
    }
  }, [title]);

  return (
    <div className={classes.root} ref={ref} {...rest}>
      <Container
        children={children}
        maxWidth={maxWidth}
        style={{
          padding: rest.noPagding ? '0' : '15px',
        }}
      />
    </div>
  );
});

Page.defaultProps = {
  maxWidth: false,
  siteTitle: SITE_TITLE,
};

Page.displayName = 'Page';

Page.propTypes = {
  children: PropTypes.node,
  favicon: PropTypes.string,
  maxWidth: PropTypes.oneOf([
    'lg',
    'md',
    'sm',
    'xl',
    'xs',
    false,
  ]),
  siteTitle: PropTypes.string,
  title: PropTypes.string,
};

export default Page;
