import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import {
  InputCode,
  InputClabe,
  InputCreditCard,
  InputCreditCardExpireTime,
  InputCurrency,
  InputMxPhone,
  InputPercentage,
} from './inputs';

function NumberControl(props) {
  const {
    disabled,
    events = {},
    name,
    settings: { format },
    size,
    onBlur,
    onChange,
  } = props;

  const inputComponent = {
    auth_code: InputCode,
    clabe: InputClabe,
    credit_card: InputCreditCard,
    credit_card_expire_time: InputCreditCardExpireTime,
    currency: InputCurrency,
    mx_phone: InputMxPhone,
    percentage: InputPercentage,
  }[format];

  return (
    <TextField
      disabled={disabled}
      error={props.error}
      fullWidth={true}
      helperText={props.helperText}
      label={props.label}
      name={name}
      size={size}
      variant="outlined"
      value={props.value}
      onBlur={onBlur}
      onChange={({ target }) => {
        let { value } = target;
        if (!inputComponent) {
          value = parseFloat(value.replace(/[^\d.-]/g, ''));
        }

        onChange({
          target: {
            name,
            value,
          },
        });

        if (events.onChange) {
          events.onChange({
            name,
            value: value || '',
          });
        }
      }}
      type={inputComponent ? 'text' : 'number'}
      InputProps={{
        inputComponent,
      }}
    />
  );
}

NumberControl.defaultProps = {
  error: false,
  settings: {},
  onBlur: () => { },
  onChange: () => { },
};

NumberControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  settings: {
    format: PropTypes.oneOf([
      'clabe',
      'credit_card',
      'credit_card_expire_timr',
      'currency',
      'mx_phone',
      'percentage',
    ]),
  },
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default NumberControl;
