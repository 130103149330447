import { combineReducers } from 'redux';

import publicFormsReducer from './publicForms';
import recoveryReducer from './recovery';
import systemReducer from './system';

const rootReducer = combineReducers({
  publicForms: publicFormsReducer,
  system: systemReducer,
  recovery: recoveryReducer,
});

export default rootReducer;
