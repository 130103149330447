import {
  CODE_SENDED,
  CODE_FAILURE,
  CODE_FETCHING,
  RECOVERY_SUCCESS,
} from 'redux/constants/auth';

const initialState = {
  sended: false,
  isFeching: false,
  error: null,
  username: '',
};

function recoveryReducer(state = initialState, action) {
  switch (action.type) {
    case CODE_FETCHING:
      return {
        ...state,
        isFeching: true,
      };
    case RECOVERY_SUCCESS:
      return initialState;

    case CODE_FAILURE:
      return {
        ...state,
        isFeching: false,
        error: action.error,
      };
    case CODE_SENDED:
      return {
        isFetching: false,
        sended: true,
        error: null,
        username: action.username,
      };
    default:
      return state;
  }
};

export default recoveryReducer;
