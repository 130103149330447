export const CITIES = [
  {
    label: 'Aguascalientes',
    value: 'Aguascalientes', 
  },
  {
    label: 'Baja California Norte',
    value: 'Baja California Norte', 
  },
  {
    label: 'Baja California Sur',
    value: 'Baja California Sur', 
  },
  {
    label: 'Campeche',
    value: 'Campeche', 
  },
  {
    label: 'Chihuahua',
    value: 'Chihuahua', 
  },
  {
    label: 'Chiapas',
    value: 'Chiapas', 
  },
  {
    label: 'Coahuila',
    value: 'Coahuila', 
  },
  {
    label: 'Colima',
    value: 'Colima', 
  },
  {
    label: 'Distrito Federal',
    value: 'Distrito Federal', 
  },
  {
    label: 'Durango',
    value: 'Durango', 
  },
  {
    label: 'Guerrero',
    value: 'Guerrero', 
  },
  {
    label: 'Guanajuato',
    value: 'Guanajuato', 
  },
  {
    label: 'Hidalgo',
    value: 'Hidalgo', 
  },
  {
    label: 'Jalisco',
    value: 'Jalisco', 
  },
  {
    label: 'Edo. Mexico',
    value: 'Edo. Mexico', 
  },
  {
    label: 'Michoacán',
    value: 'Michoacán', 
  },
  {
    label: 'Morelos',
    value: 'Morelos', 
  },
  {
    label: 'Nayarit',
    value: 'Nayarit', 
  },
  {
    label: 'Nuevo León',
    value: 'Nuevo León', 
  },
  {
    label: 'Oaxaca',
    value: 'Oaxaca', 
  },
  {
    label: 'Puebla',
    value: 'Puebla', 
  },
  {
    label: 'Queretaro',
    value: 'Queretaro', 
  },
  {
    label: 'Quintana Roo',
    value: 'Quintana Roo', 
  },
  {
    label: 'Sinaloa',
    value: 'Sinaloa', 
  },
  {
    label: 'San Luis Potosí',
    value: 'San Luis Potosí', 
  },
  {
    label: 'Sonora',
    value: 'Sonora', 
  },
  {
    label: 'Tabasco',
    value: 'Tabasco', 
  },
  {
    label: 'Tamaulipas',
    value: 'Tamaulipas', 
  },
  {
    label: 'Tlaxcala',
    value: 'Tlaxcala', 
  },
  {
    label: 'Veracruz',
    value: 'Veracruz', 
  },
  {
    label: 'Yucatán',
    value: 'Yucatán', 
  },
  {
    label: 'Zacatecas',
    value: 'Zacatecas', 
  },
];
