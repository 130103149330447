import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import dot from 'dot-object';

function SelectAutocomplete(props) {
  const {
    disabled,
    error,
    events = {},
    label,
    name,
    helperText,
    size,
    value,
    onBlur,
    onChange,
    useSetFieldValues,
  } = props;
  let { options = [] } = props;

  if (typeof options === 'function') {
    options = options();
  }

  return (
    <Autocomplete
      disabled={disabled}
      onChange={(e, value) => {
        const target = {
          name,
          value: value?.id,
        };

        onChange({
          target,
        });

        if (events.onChange) {
          const [setFieldValue, values] = useSetFieldValues;
          dot.str(target?.name, target?.value, values);
          events.onChange(
            target,
            setFieldValue,
            /*{
              ...values,
              [target.name]: target.value,
            },*/
            values,
          );
        }
      }}
      onBlur={onBlur}
      options={options}
      getOptionLabel={({ name }) => name}
      groupBy={({ groupBy }) => groupBy}
      defaultValue={options.find(_ => String(_.id) === String(value))}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          fullWidth
          size={size}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
        />)}
    />);
}

function SelectMenu(props) {
  const {
    disabled,
    emptyElement,
    error,
    label,
    name,
    group,
    helperText,
    events = {},
    size,
    value,
    onBlur,
    onChange,
    useSetFieldValues,
  } = props;
  let { options = [] } = props;

  if (typeof options === 'function') {
    options = options();
  }

  const groups = group ? [...new Set(
    options.map(({ groupBy }) => groupBy),
  )] : [];

  return (
    <TextField
      autoComplete={true}
      disabled={disabled}
      error={error}
      fullWidth={true}
      helperText={helperText}
      label={label}
      name={name}
      select={true}
      SelectProps={{
        native: true,
      }}
      size={size}
      variant="outlined"
      value={value}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          const [setFieldValue, values] = useSetFieldValues;
          dot.str(e?.target?.name, e?.target?.value, values);
          events.onChange(
            e?.target,
            setFieldValue,
            /*{
              ...values,
              [e?.target?.name]: e?.target?.value,
            },*/
            values,
          );
        }
      }}
    >
      {emptyElement && (<option>{emptyElement}</option>)}
      {group && (groups.map((item, index) => {
        const opt = options.filter((_) => _.groupBy === item);

        return (opt.length ? <optgroup key={index} label={item}>
          {opt.map(({ id, name }, key) => (
            <option value={id} key={key}>
              {name}
            </option>
          ))}
        </optgroup> : <></>);
      }))}
      {!group && options.map(({ id, name }, key) => (
        <option value={id} key={key}>
          {name}
        </option>
      ))}
      {/* {options.map(({ id, name, groupBy }, index) => (
        <>
          {(group && options[index - 1]?.groupBy !== groupBy) && (
            <ListSubheader>
              <Typography color="textSecondary" variant="subtitle1">
                {groupBy}
              </Typography>
            </ListSubheader>
          )}
          <MenuItem key={index} value={id}>
            {name || id}
          </MenuItem>
        </>
      ))} */}
    </TextField>
  );
}

function SelectControl(props) {
  const {
    disabled,
    label,
    name,
    settings: {
      autocomplete = false,
      emptyElement,
      group = false,
      options = [],
    },
    events = {},
    size,
    value,
    error,
    helperText,
    onBlur,
    onChange,
    useSetFieldValues,
  } = props;
  const Control = autocomplete ? SelectAutocomplete : SelectMenu;

  return (
    <Control
      disabled={disabled}
      emptyElement={emptyElement}
      error={error}
      events={events}
      group={group}
      helperText={helperText}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      size={size}
      useSetFieldValues={useSetFieldValues}
      value={value}
    />
  );
}

SelectAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
  })),
  size: PropTypes.string,
  useSetFieldValues: PropTypes.array,
  value: PropTypes.any,
};

SelectMenu.propTypes = {
  disabled: PropTypes.bool,
  emptyElement: PropTypes.string,
  error: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  group: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
    })),
    PropTypes.func,
  ]),
  events: PropTypes.object,
  size: PropTypes.string,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  useSetFieldValues: PropTypes.array,
};

SelectControl.defaultProps = {
  disabled: false,
  events: {},
  onChange: () => { },
  onBlur: () => { },
  settings: {
    autocomplete: false,
    group: false,
    options: [],
  },
  size: 'small',
  useSetFieldValues: [() => { }, {}],
};

SelectControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  settings: PropTypes.shape({
    autocomplete: PropTypes.bool,
    emptyElement: PropTypes.string,
    group: PropTypes.bool,
    nullItem: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ]),
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  useSetFieldValues: PropTypes.array,
};

export default SelectControl;
